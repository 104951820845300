const reducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_CALENDAR":
      return action.payload;
    case "ADD_CALENDAR":
      return [...state, action.payload];
    case "UPDATE_CALENDAR":
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    case "REMOVE_CALENDAR":
      return state.filter((s) => s.id !== action.payload);
    case "CLEAR_CALENDAR":
      return [];
    default:
      return state;
  }
};
export default reducer;
