import { combineReducers } from "redux";
import authReducer from "./authReducer";
import batchReducer from "./batchReducer";
import calendarReducer from "./calendarReducer";
import catReducer from "./catReducer";
import collegeReducer from "./collegeReducer";
import dashboardReducer from "./dashboardReducer";
import decodedReducer from "./decodedReducer";
import departmentReducer from "./departmentReducer";
import grievanceReducer from "./grievanceReducer";
import newsReducer from "./newsReducer";
import noticeReducer from "./noticeReducer";
import profileReducer from "./profileReducer";
import programmeReducer from "./programmeReducer";
import queryReducer from "./queryReducer";
import readNoticeReducer from "./readNoticeReducer";
import semesterReducer from "./semesterReducer";

export default combineReducers({
  auth: authReducer,
  batch: batchReducer,
  calendar: calendarReducer,
  cat: catReducer,
  college: collegeReducer,
  dashboard: dashboardReducer,
  grievance: grievanceReducer,
  decoded: decodedReducer,
  department: departmentReducer,
  news: newsReducer,
  notice: noticeReducer,
  profile: profileReducer,
  programme: programmeReducer,
  query: queryReducer,
  readNotice: readNoticeReducer,
  semester: semesterReducer,
});
